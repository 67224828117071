<template>
<pre>
ONLINE EMPLOYEE PORTAL TERMS OF USE

Effective Date:  December 14, 2017
Last Updated:  December 14, 2017

PLEASE READ THESE TERMS OF USE ("TERMS") CAREFULLY BEFORE USING THE LARKIN COMPANY'S EMPLOYEE PORTAL.  These Terms constitute a binding, legal agreement between you and Larkin Benefit Administrators, a California corporation doing business as The Larkin Company, and its affiliates ("Larkin" or "we" or "us").  These Terms govern the access and use of The Larkin Company's Employee Portal by our company clients who are parties to current, existing written agreements with us ("Employers"), those Employers' employees ("Employee Users"), and any other person who accesses and/or uses our online services (collectively referred to as "Users" or "you"). Our online services include this Employee Portal, our website, www.thelarkincompany.com, any other websites, microsites, mobile sites or applications and their respective portals, e-mail notifications, and short messaging system (SMS) (collectively, the "Services").

In order to use Larkin's Employee Portal, (1) your Employer must be a party to a separate, existing written agreement or series of agreements with Larkin, and (2) you must be given permission by your Employer to access and use Larkin's Employee Portal, using an individual username and password.  Unless both (1) and (2) are true, you are prohibited from using Larkin's Employee Portal.

By clicking the acceptance button or accessing and/or using the Services, you agree to be bound and abide by these Terms. If you do not agree to the Terms, do not access or use the Services and exit the Services now. Your agreement to be bound and abide by these Terms becomes effective immediately when you access or use our Services.

Your Representations and Warranties

You represent, warrant, and covenant that you have the authorization to use and access the Services; that you are allowed to access and use the Services under applicable laws; that you are at least 18 years old; and that all information you provide to us or enter into the Services is at all times true, accurate, and complete.

For HR Users: you represent, warrant, and covenant, that you are authorized to act on behalf of the Employer.  Your acceptance of these terms binds you and your Employer.

Authorized Access and Use of Services; Limited License

Larkin owns or has the right to use all of its Services, including this Employee Portal, information and materials ("Content"), and the features and functionality of the Services. The Employee Portal, Content, features and functionality, and other elements of the Services are protected by copyright, trademark, trade secrets and other intellectual property and proprietary rights and laws. Content may be downloaded, copied, and printed solely for your benefit in a manner permitted by the Terms. You agree not to copy, distribute, decompile, disassemble, reverse engineer, modify, attempt to derive the source code of, or create derivative works of any portion of the Services, including without limitation this Employee Portal.

On condition of your continued acceptance of, and compliance with these Terms, Larkin grants you a revocable, limited, non-sublicensable, non-assignable, non-exclusive license to access and use the Services, for the sole purpose of enabling you to use and enjoy the benefit of the Services in the manner permitted by the Terms.

You agree not to use the Services in any manner that violates any federal, state, local or international law or regulation, including without limitation any laws regarding the export of data or software to and from the U.S. or other countries.  You also agree not to use the Services in any manner that could interfere with, damage, impair, disable, or overburden the Services or any party's use of the Services. You agree not to upload or distribute through the Services any material that contains a virus or any other computer code, file or program, or any other harmful component that is intended to disrupt, damage, or limit, the functioning of the Services or to obtain unauthorized access to any data or information of any third party.

We have the right and the sole discretion to remove any content posted, shared, or transmitted by you that, in our judgment, does not comply with the Terms or that is otherwise unlawful, harmful, objectionable, or inaccurate. We are not responsible for any failure or delay in removing such content.

Larkin reserves the right to withdraw, alter or amend this Employee Portal, and any provided Service or Content in its sole discretion and without notice.  Larkin will not be liable if for any reason all or any part of the Employee Portal is unavailable at any time for any duration of time.

Use of Services to Perform or Participate in any Illegal Activity is Prohibited

You agree to access and use the Services in accordance with the Terms and only for lawful purposes. You may not use the Services in a way that violates any applicable federal, state, local or international laws or regulations.

You agree not to access or use the Services for spamming activities or to disseminate or transmit material that is unlawful, abusive, deceptive, fraudulent, invasive of another's privacy, obscene, pornographic, defamatory, harassing, grossly offensive, vulgar, threatening, malicious or that otherwise violates our rules or policies, including these Terms. You agree not to access or use the Services to create a false identity, commit identity theft, or to otherwise attempt to mislead any person as to the identity or origin of any communication.

You agree not to access or use the Services to infringe on any patent, trademark, trade secret, copyright, right of publicity, or other proprietary right of any party.

You agree that we may at any time, and at our sole discretion, terminate your access to or use of the Services, or any feature within the Services, without prior notice to you.

Links to Third-Party Websites

The Services may contain links to third-party websites. Larkin does not endorse these websites or the products or services offered on them, and these links are provided as a convenience. Larkin does not control or review the third-party websites and is not responsible for any conduct, activity or content on these websites.

Changes to Terms

Larkin may modify, revise, or update the Terms at any time, without notice to you. All changes are effective the moment we post them and apply to all access to and use of the Services thereafter. Your continued access to and use of the Services following the posting of revised Terms means that you agree to and accept the changes. You agree that it is your responsibility to review periodically the Terms and to be aware of any changes.

Communication

You agree to receive communications, including those generated by automatic telephone dialing systems, sent by or on behalf of Larkin, including via e-mail, regular mail, calls, push notifications to the cellular number you provided us, text messages, SMS, MMS, postings on the Service, or other means now known or hereinafter developed.

Warranty Disclaimer

ALL SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT WARRANTIES, REPRESENTATIONS, AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR USE OR PURPOSE, OR THE WARRANTY OF NONINFRINGEMENT. WITHOUT LIMITING THE FOREGOING, WE MAKE NO REPRESENTATION, WARRANTY, OR GUARANTEE THAT 1) THE SERVICES WILL BE RELIABLE, SECURE, TIMELY, AVAILABLE, ERROR FREE, SUITABLE, ACCURATE, OR COMPLETE; 2) THE SERVICES WILL MEET YOUR NEEDS OR REQUIREMENTS; 3) THE QUALITY OF THE SERVICES OR INFORMATION DERIVED THEREFROM WILL MEET YOUR EXPECTATIONS OR BE FREE FROM DEFECTS, MISTAKES, OR ERRORS.

THE USE OF THE SERVICES OR THE DOWNLOADING OR OTHER ACQUISITION OF ANY MATERIALS THROUGH THE SERVICES IS DONE AT YOUR OWN DISCRETION AND RISK. YOU ACKNOWLEDGE THAT TRANSMISSIONS OF DATA TO AND FROM THE SERVICES COULD BE READ OR INTERCEPTED BY OTHERS. WE CANNOT AND DO NOT GUARANTEE THAT FILES AVAILABLE FOR DOWNLOADING FROM THE SERVICES WILL BE FREE FROM VIRUSES OR DESTRUCTIVE CODE. YOU AGREE THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM SUCH ACTIVITIES.

YOU UNDERSTAND AND AGREE THAT FROM TIME TO TIME THERE MAY BE AN OUTAGE IN THE SERVICES OR THE SERVICES MAY BE INACCESSIBLE OR INOPERABLE. THIS MAY HAPPEN FOR ANY REASON, INCLUDING, WITHOUT LIMITATION, EQUIPMENT MALFUNCTIONS, INTERNET CONGESTION, PERIODIC MAINTENANCE PROCEDURES, REPAIRS, OR CAUSES BEYOND OUR CONTROL OR WHICH ARE NOT REASONABLY FORESEEN BY US.

LARKIN MAKES NO REPRESENTATIONS THAT THE MATERIALS OR CONTENT CONTAINED WITHIN THE SERVICES ARE APPROPRIATE FOR LOCATIONS OUTSIDE THE UNITED STATES. IF YOU USE THE COMPANY SITE FROM LOCATIONS OUTSIDE THE UNITED STATES, YOU ARE SOLELY RESPONSIBLE FOR COMPLIANCE WITH ANY AND ALL APPLICABLE LOCAL LAWS OF THE JURISDICTION FROM WHICH YOU ACCESS IT.

THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.

Limitation of Liability

LARKIN, ITS EMPLOYEES, DIRECTORS, OFFICERS, SHAREHOLDERS, AGENTS, LICENSORS, AND SERVICE PROVIDERS WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION, OR LOSS OF BUSINESS INFORMATION), TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ARISING OUT OF OR IN CONNECTION WITH (1) THE ACCESS OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICES; (2) THE PROVISION OF OR FAILURE TO PROVIDE THE SERVICES; (3) MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH THE COMPANY SERVICES, INCLUDING WITHOUT LIMITATION THE UPLOADING OR DOWNLOADING OF ANY MATERIALS, DATA, TEXT, IMAGES, VIDEO OR AUDIO FROM OUR SITES, INCLUDING BUT NOT LIMITED TO ANYTHING CAUSED BY ANY VIRUSES, BUGS, HUMAN ACTION OR INACTION OR ANY COMPUTER SYSTEM, PHONE LINE, HARDWARE, SOFTWARE OR PROGRAM MALFUNCTIONS, OR ANY OTHER ERRORS, FAILURES OR DELAYS IN COMPUTER TRANSMISSIONS OR NETWORK CONNECTIONS; (4) UNAUTHORIZED ACCESS TO OR ALTERATION OF USERS' TRANSMISSIONS OR DATA, EVEN IF YOU HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  NOTWITHSTANDING THE FACT THAT LARKIN OR OTHER PARTIES INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE SERVICES OR OUR SITES MAY MONITOR OR REVIEW TRANSMISSIONS, POSTINGS, DISCUSSIONS, OR CHATS, LARKIN AND ALL PARTIES INVOLVED IN CREATING, PRODUCING, DELIVERING OR OPERATING THE SERVICES AND OUR SITES, ASSUME NO RESPONSIBILITY OR LIABILITY WHICH MAY ARISE FROM THE CONTENT THEREOF.

THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.

Indemnification

You agree to defend, indemnify, and hold harmless Larkin, its shareholders, officers, directors, employees, consultants, attorneys, suppliers, agents, licensors, service providers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms, of any law or regulation, and/or of lawful and proper access to or use of the Services (collectively, the "Indemnified Claims").  You agree not to enter into any settlement of Indemnified Claims without obtaining our prior written consent, which shall not be unreasonably withheld.

We reserve the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will cooperate with us in asserting any available defenses.

Security and Passwords

Prior to accessing and using the Services, we will provide you with a password. You are solely responsible for maintaining the confidentiality of your password and account, and you are also solely responsible for any and all statements made and acts or omissions that occur through the use of your password and account. You must take steps to ensure that others do not have access to or gain access to your password and/or account. We reserve the right to, in our sole discretion, immediately disable your username, password and/or account and terminate your access to the Services in the event of an unauthorized transfer, sharing, or access of your account, or for any other violation of these Terms.

Waiver and Severability

No waiver by Larkin of any term, condition, or provision in the Terms shall be deemed a continuing waiver of such term, condition, or provision, nor a further waiver of any other term, condition or provision herein. Any failure by us to assert a right or provision under the Terms shall not constitute a waiver of such right or provision.

If any provision of these Terms is adjudicated by any court of competent jurisdiction to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary so that the Terms will otherwise remain in full force and effect.

Reservation of Rights

Larkin reserves the right to seek all remedies available at law and in equity for violations of these Terms.

Termination

Larkin may terminate these Terms or access to or use of the Services, temporarily or permanently, at any time, with or without notice, for any reason.

Privacy

Our <a href="https://www.thelarkincompany.com/privacy-policy/" target="_blank">Privacy Policy</a> contains information about how we collect, use, and/or disclose your personal information, including information that is generated by your access to and use of the Services. The Privacy Policy also contains information about how we protect your data. Through your access and use of the Services and agreement to be bound and abide by these Terms, you acknowledge that you have read our Privacy Policy and consent to the collection, use, and disclosure of this information, as described in our Privacy Policy.

Governing Law, Jurisdiction, Venue and Right to Attorneys Fees

The Terms and any other agreement incorporated herein shall be governed by and construed with the laws of the State of California and the applicable laws of the United States, without regard to conflict of law principles thereof. You hereby consent and submit to the personal and exclusive jurisdiction of the courts of the State of California in any action or dispute related to the Terms and any other agreement incorporated thereby. You agree that any legal proceeding to interpret, enforce, or that otherwise arise out of or relate to these Terms or Larkin's Services, including without limitation mediation, arbitration, administrative proceedings, and/or court litigation, shall be venued in Santa Clara County, California.

In any dispute arising out of or relating to these Terms, including without limitation mediation, arbitration or litigation, the prevailing party is entitled to recover its reasonable attorneys' fees and costs, in addition to any other remedies it may recover.

Entire Agreement

The Terms, Privacy Policy, and any other agreement referred to in the Terms constitute the sole and entire agreement between you and Larkin regarding the subject matters in those agreements, and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding the Services.


Contact Us

If you have any questions or comments, please contact Larkin at <a href="mailto:terms@thelarkincompany.com" class="hyperlink-link">terms@thelarkincompany.com</a> or (866) 923-3336.
</pre>
</template>

<script>
  export default {
    name: 'TermsOfService'
  }
</script>
