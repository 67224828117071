<template>
  <div class="m-2 mt-5 p-3 pb-5">
    <TermsOfService />
  </div>
</template>
<script>
  import TermsOfService from '@/components/TermsOfService.vue'

  export default {
    name: 'termsOfService',
    components: {
      TermsOfService
    }
  }
</script>